import React from 'react';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import PhoneIcon from '@mui/icons-material/Phone';
import RoomIcon from '@mui/icons-material/Room';
import { Link } from 'react-router-dom';
import { fetchResponse } from '../../restapiutil/RestAPIClient';
class AKTUELLES extends React.Component {
  constructor(props) {
    super(props);
    this.state = {text:""};
   

}

  async componentDidMount (){
    this.getMittagsmenu();
  
}

  async  getMittagsmenu() {
    const APPLICATION_JSON = "application/json";
    var dataurl= "reserve/mittagsmenu";
   
    const headers = { Accept : APPLICATION_JSON, "Content-Type": APPLICATION_JSON };
    const response = await fetchResponse(dataurl,"GET",headers,{});
    if(response != null && response.responseData!=null){

        this.setState({text:decodeURI(response.responseData.data)});
        console.log(this.state);
    }
}
   
  render() {
    var height = window.innerHeight-( window.innerHeight*0.25);
    return (
        <>
        <p>Wir bieten ab jetzt auch ein Mittagsbuffet für Sie an.<br/>
        Dies wir täglich aktualisiert.</p>
        
        { <div dangerouslySetInnerHTML={{ __html: this.state.text }} /> }

       
        {/* <img src='/Mittagsmenukarte.jpg' style={{width:"100%"}}/> */}


        </>
        );
  }
}
export default AKTUELLES; 