import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import img from '../logo-1.png';
import { Link } from 'react-router-dom';
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const pages = [ {link:'/',text:'Home',subMenu: []},{link:"/AKTUELLES",text:"AKTUELLES"
  ,subMenu: [{
  key: "Weihnachtsmenü",link:'/Weihnachtsmenu'},{
    key: "Mittagsbuffet",link:'/Mittagsmenu'},{
      key: "Silvesterbuffet",link:'/Silvesterbuffet'}
]},{link:'/brunch',text:'FRÜHSTÜCK & BRUNCH',subMenu: []}, {link:'/reservierung',text:'Reservierung',subMenu: []}, {link:'/Kulinarische',text:'Kulinarische Genüsse',subMenu: []}
,{link:'/catering',text:'Catering',subMenu: []}];
//const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" color="transparent" >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
       
          {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
          {/* <Typography
            variant="h6"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
           
          </Typography> */}
         
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
              style={{backgroundColor:'transparent'}}
            >
              {pages.map((page) => (
                // <Link to={page.link} style={{ textDecoration: 'none' }}>
                // <MenuItem key={page.text}  onClick={handleCloseNavMenu} style={{background:'#d7ae2f',color:'#112f8a', margin:'1px'}}>
                //   <Typography textAlign="center" >{page.text}</Typography>
                // </MenuItem></Link>
                <MenuItemWithSubMenu data={page} key={page.text} handleCloseNavMenu={handleCloseNavMenu}></MenuItemWithSubMenu>
              ))}
            </Menu>
            <img src={img}  style={{width:'29%',margin:'auto'}}/>
          </Box>
          {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
          {/* <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            LOGO
          </Typography> */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
            //   <Link to={page.link} style={{ textDecoration: 'none' }}>
            //  <Button
            //     key={page.text}
            //     onClick={handleCloseNavMenu}
            //     sx={{ my: 2, color: 'white', display: 'block' }}
            //     style={{color:'#9f2f3c'}}
            //   >
            //     {page.text}
            //   </Button></Link>
            <MenuItemWithSubMenu data={page} key={page.text} handleCloseNavMenu={handleCloseNavMenu}></MenuItemWithSubMenu>
            
            ))}
             <img src={img}  style={{width:'10%',margin:'auto'}}/>
          </Box>

         
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;

const MenuItemWithSubMenu = ({ data,handleCloseNavMenu }) => {
  const { text, subMenu,link } = data || {};
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuRef = React.useRef(null);
  const [parentWidth, setParentWidth] = React.useState(0);
  const [parentHt, setParentHt] = React.useState(0);

  React.useEffect(() => {
    const { offsetWidth, offsetHeight } = menuRef?.current || {};
    setParentWidth(offsetWidth);
    setParentHt(offsetHeight);
  }, [menuRef]);

  return (
    <>
      <MenuItem
        ref={menuRef}
        id={text}
        onClick={(e) => setAnchorEl(e.currentTarget) }
        style={{background:'transparent',color:'#9f2f3c', margin:'1px'}}
      >
       {subMenu.length == 0 && <Link to={link} style={{ textDecoration: 'none' }}><Button
                key={text}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
                style={{color:'#9f2f3c'}}
              > {text}</Button></Link>} {subMenu.length > 0 && <><Button  key={text}   sx={{ my: 2, color: 'white', display: 'block' }}
                style={{color:'#9f2f3c'}}
              >{text}</Button><ArrowRightIcon /></>}
      </MenuItem>
      {subMenu.length > 0 && (
        <Menu
          id="basic-sub-menu"
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": text
          }}
          style={{
            left: parentWidth * 0.9,
            top: -(parentHt * 0.53)
          }}
        >
          {subMenu.map((subMenuItem, i) => (
            <Link to={subMenuItem.link} style={{ textDecoration: 'none' }}><MenuItem key={i} onClick={() => {setAnchorEl(null);handleCloseNavMenu()}} style={{background:'transparent',color:'#9f2f3c', margin:'1px'}}>
              {subMenuItem.key}
            </MenuItem></Link>
          ))}
        </Menu>
      )}
    </>
  );
};